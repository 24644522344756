import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { Link, Element } from 'react-scroll'
import BackgroundImage from 'gatsby-background-image'
import { useTranslation } from 'react-i18next'
import { cover } from 'polished'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MaterialLink from '@material-ui/core/Link'

import Section from '../components/Section'
import Heading from '../components/Heading'
import Hero from '../components/Hero'
import Button from '../components/Button'
import Team from '../components/Team'
import Partners from '../components/Partners'
import Gallery from '../components/Gallery'
import Club from '../components/Club'

import GatsbyLink from '../components/Link'

import pagesInfos from '../config/i18n/pagesInfos'

const useStyles = makeStyles(theme => ({
  eventHeader: {
    height: '150px',
    padding: theme.spacing(2),
    backgroundPosition: 'top',
    borderRadius: '10px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    position: 'relative',
    textTransform: 'uppercase',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  eventHeaderCover: {
    ...cover(),
    backgroundColor: theme.palette.primary.main,
    opacity: 0.6,
    zIndex: -1,
  },
  h3: {
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(6),
  },
  grid: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },
  alert: {
    background: '#FFF3CD',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(15),
    borderRadius: '4px',
    fontWeight: 600,
    border: 'solid 4px ' + theme.palette.primary.main,
  },
  alertTitle: {
    color: theme.palette.primary.main,
  },
}))

const IndexPage = ({}) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      bgEvents: file(relativePath: { eq: "bg_events.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Helmet
        title={t('home:head.title')}
        meta={[
          {
            name: 'description',
            content: t('home:head.description'),
          },
        ]}
      />
      <Element id="accueil" name="accueil">
        <Hero title={t('home:accueil.title')}>
          <Link to="cycles-stages" smooth={true} duration={500}>
            <Button>{t('home:accueil.button')}</Button>
          </Link>
        </Hero>
      </Element>
      <Element id="notre-ecole" name="notre-ecole">
        <Section bg="#E7F1FE">
          {/* 
          
          <div className={classes.alert}>
            <Typography
              component="h3"
              variant="h4"
              color="secondary"
              className={classes.alertTitle}
            >
              <strong>Information COVID-19</strong>
            </Typography>
            <p>
              Bonjour à tous, suite aux décisions gouvernementales, tous les
              cours du cycle hiver 2019-2020 sont annulés sur nos 3 sites et ce
              jusqu’au 29 mars
            </p>
            <Button
              variant="secondary"
              title="Communication Covid-19 Cycle hiver 2019-2020"
              href="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/Communication%20Covid-19%20Cycle%20hiver%202019-2020.pdf"
            >
              Plus d'informations en cliquant ici
            </Button>
          </div>
          */}

          <Heading title={t('home:ecole.title')} variant="secondary" />
          <Typography
            component="h3"
            variant="h4"
            color="secondary"
            className={classes.h3}
          >
            <strong> {t('home:ecole.equipe.title')}</strong>
          </Typography>
          <p>{t('home:ecole.equipe.text')}</p>

          <Grid container className={classes.grid}>
            <Team variant="secondary" />
            <Grid item xs={12} container justify="center">
              <GatsbyLink
                page={pagesInfos.ComponentProfesseursDeTennis}
                className={classes.button}
              >
                <Button variant="secondary">
                  {t('home:ecole.projet.button')}
                </Button>
              </GatsbyLink>
            </Grid>
          </Grid>

          <Typography
            component="h3"
            variant="h4"
            color="secondary"
            className={classes.h3}
          >
            <strong> {t('home:ecole.clubs.title')}</strong>
          </Typography>
          <Grid
            container
            spacing={3}
            className={classes.grid}
            alignItems="stretch"
          >
            <Grid item sm={3} xs={12}>
              <Club club={t('clubs:royal', { returnObjects: true })} />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Club club={t('clubs:churchill', { returnObjects: true })} />
            </Grid>
            {/* 
                <Grid item sm={3} xs={12}>
              <Club club={t('clubs:leopold', { returnObjects: true })} />
            </Grid>
            */}

            <Grid item sm={3} xs={12}>
              <Club club={t('clubs:montjoie', { returnObjects: true })} />
            </Grid>  
          </Grid>

          <Typography
            component="h3"
            variant="h4"
            color="secondary"
            className={classes.h3}
          >
            <strong> {t('home:ecole.projet.title')}</strong>
          </Typography>
          <p>{t('home:ecole.projet.text')}</p>
          <ul>
            <li>{t('home:ecole.projet.list.0')}</li>
            <li>{t('home:ecole.projet.list.1')}</li>
            <li>{t('home:ecole.projet.list.2')}</li>
          </ul>
          <Grid container justify="center">
            <GatsbyLink
              page={pagesInfos.ComponentProjetPedagogique}
              className={classes.button}
            >
              <Button variant="secondary">
                {t('home:ecole.projet.button')}
              </Button>
            </GatsbyLink>
          </Grid>
        </Section>
      </Element>
      <Element id="cycles-stages" name="cycles-stages">
        <Section>
          <Heading title={t('home:cycles-stages.title')} variant="secondary" />
          <Grid container spacing={3} className={classes.grid}>
            <Grid item sm={6} xs={12}>
              <BackgroundImage
                Tag="div"
                fluid={data.bgEvents.childImageSharp.fluid}
                className={classes.eventHeader}
                backgroundColor={`#040e18`}
              >
                <div className={classes.eventHeaderCover} />
                <Typography component="h3" variant="h5" color="inherit">
                  <strong>{t('home:cycles-stages.cycles.title')}</strong>
                </Typography>
              </BackgroundImage>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('home:cycles-stages.cycles.text1'),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t('home:cycles-stages.cycles.text2'),
                }}
              />
              <div style={{ padding: 0 }}>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.grid}>
                  <GatsbyLink page={pagesInfos.ComponentCyclesEtStagesCyclePrintemps2024}>
                    <Button fullWidth>
                    {t('home:cycles-stages.cycles.cycle-printemps')} 2024
                    </Button>
                  </GatsbyLink>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <BackgroundImage
                Tag="div"
                fluid={data.bgEvents.childImageSharp.fluid}
                className={classes.eventHeader}
                backgroundColor={`#040e18`}
              >
                <div className={classes.eventHeaderCover} />
                <Typography component="h3" variant="h5" color="inherit">
                  <strong> {t('home:cycles-stages.stages.title')}</strong>
                </Typography>
              </BackgroundImage>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('home:cycles-stages.stages.text1'),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t('home:cycles-stages.stages.text2'),
                }}
              />
              <div style={{ padding: 15 }}></div>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.grid}>
                  <GatsbyLink page={pagesInfos.ComponentCyclesEtStagesStages}>
                    <Button fullWidth>
                      {t('home:cycles-stages.stages.stages')} 2024
                    </Button>
                  </GatsbyLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          
          <Typography
            component="h3"
            variant="h4"
            color="secondary"
            className={classes.h3}
            id="padel" 
            name="padel"
          >
            <strong> {t('home:padel.title')}</strong>
          </Typography>

          <p>
            {t('home:padel.content', {
                returnObjects: true,
              }).map((text, key) => (
                <p
                  key={key.toString()}
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                ></p>
              ))}
          </p>

          <Typography
            component="h3"
            variant="h4"
            color="secondary"
            className={classes.h3}
          >
            <strong> {t('home:cycles-stages.yoga.title')}</strong>
          </Typography>
          <p>{t('home:cycles-stages.yoga.text')}</p>
          <Typography variant="subtitle2" color="inherit">
            <strong>{t('home:cycles-stages.yoga.subtitle')}</strong>
          </Typography>
          <ul>
            <li>
              <strong>Antoine : +32 489 64 14 55</strong>
            </li>
            <li>
              <MaterialLink
                href="http://www.feelgoodinthewood.be"
                title="Feel Good In The Wood"
                target="_blank"
                rel="noopener"
              >
                www.feelgoodinthewood.be
              </MaterialLink>
            </li>
            <li>
              <MaterialLink
                href="mailto:info@feelgoodinthewood.be"
                target="_blank"
                rel="noopener"
              >
                info@feelgoodinthewood.be
              </MaterialLink>
            </li>
            <li>
              <MaterialLink
                href="https://fr-fr.facebook.com/feelgoodinthewood/"
                target="_blank"
                rel="noopener"
              >
                facebook.com/feelgoodinthewood
              </MaterialLink>
            </li>
          </ul>
        </Section>
      </Element>
      <Element id="news" name="news">
        <Section bg="#fafcff">
          <Heading title={t('home:news.title')} variant="secondary" />
          <p>{t('home:news.text')}</p>
          <Gallery />
        </Section>
      </Element>
      <Element id="partners" name="partners">
        <Section bg="#E7F1FE">
          <Heading title={t('home:partners.title')} variant="secondary" />

          <Partners />
        </Section>
      </Element>
    </React.Fragment>
  )
}

IndexPage.defaultProps = {}

export default IndexPage
